@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;600;700;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .pointer-events-all {
        pointer-events: all;
    }

    .noArrow::-webkit-inner-spin-button,
    .noArrow::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

  .full-shadow {
    box-shadow: 0px 4px 12px rgba(32, 35, 37, 0.2);
  }

  .scrollbar-rounded::-webkit-scrollbar-thumb {
    border-radius: 8px;
  }

  .scrollbar-height::-webkit-scrollbar {
    height: 4px;
 }

 .h-nav {
  height: calc(100vh - 4rem)
 }
}

@layer base {
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }

  body {
    font-family: Kanit, sans-serif;
  }

  input[type="range"]::-webkit-slider-thumb {
    width: 4px;
    -webkit-appearance: none;
    appearance: none;
    height: 4px;
    cursor: ew-resize;
    background: #FFF;
    box-shadow: -405px 0 0 400px #000000;
    border-radius: 100%;
  }

}
